<template>
  <main>
    <h1>Create Your First Reminder!</h1>    
    <transition mode="out-in">
    <div v-if="!hasEventName">
      <p>What's the event?</p>
      <div>
               <input type="text" name="" id="" v-model="eventName" required>

      </div>
      <button v-if="eventName.length>3" @click="hasEventName=true;showDate=true">Next</button>
    </div>
    <div v-else-if="showDate">
      <h2>When is it?</h2>
      <p>Choose betwen 7 and 14 days from now.</p>

      <Datepicker :minDate="earliestDate()" :maxDate="latestDate()" v-model="dateTime"/>
      <button @click="showDate=false;hasEventName=false">Back</button><button v-if="dateTime" @click="showDate=false;showName=true">Next</button>
    </div>
    <div v-else-if="showName">
      <p>What's your name?</p>
      <div>
              <input type="text" name="" id="" v-model="userName">

      </div>
      <button @click="showName=false;showDate=true">Back</button><button v-if="userName.length>2" @click="showName=false;showContact=true">Next</button>
    </div>
    <div v-else-if="showContact">
      <p>Okay {{userName}}, how would you like to get your reminders?</p>
      <label for="text">Text Me</label><input type="radio" name="" value="text" id="text" v-model="contactPreference">
      <label for="email">Email Me</label><input type="radio" name="" value="email" id="email" v-model="contactPreference" >
      <vue-tel-input v-if="contactPreference=='text'" v-model="phone" ref="selector"/>
      <div v-if="contactPreference=='email'">
      <label for="email">Email address:</label><input v-if="contactPreference=='email'" type="email" name="" id="" v-model="email">
      </div>
      <div>
              <button @click="showContact=false;showName=true">Back</button><button v-if="phone||email.indexOf('@')!=-1" @click="checkNumber()">Next</button>

      </div>
    </div>
    <div v-else-if="showAdditional">
      <p>Want to add any additional information?</p>
      <div>
              <textarea name="" id="" cols="30" rows="10" maxlength="100" v-model="additionalInfo" placeholder="Dont' forget... Please bring... etc."></textarea>

      </div>
      <button @click="showAdditional=false; showContact=true">Back</button><button @click="showAdditional=false;showConfirmation=true">Next</button>
    </div>
    <div v-else-if="showConfirmation">
      <div>
              <p>{{userName}}, check the details below, and if everything looks good, hit 'Autoremind' and we'll send you timely reminders.</p>
              <p>Click 'Go Back' to make any changes</p>

      </div>
      <p>The Event: {{eventName}}</p>
      <p>When? {{dateTime}}</p>
      <p>Send reminders to {{phone?phone:email}}</p>
      <p v-if="additionalInfo">Include: {{additionalInfo}}</p>
      <div>
              <button @click="showAdditional=true;showConfirmation=false">Go Back</button>
      <button @click="autoremind()">Autoremind!</button>
      </div>

    </div>
    <div v-else-if="loading">
      Autoreminding...
    </div>
    <div v-else-if="scheduled">
      Great! Reminders have been set up! You'll receive notification by {{contactPreference}} within a few days. You can close this window.
    </div>
    </transition>
    
  </main>
</template>

<script>
import {DateTime} from 'luxon'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css' 
import axios from 'axios'


export default {
  name: 'App',
  components: {
    Datepicker,

  },
  data(){
    return{
      eventName:'',
      showDate:false,
      hasEventName:false,
      showName:false,
      dateTime:null,
      showAdditional:false,
      showContact:false,
      showConfirmation:false,
      scheduled:false,
      additionalInfo:'',
      userName:'',
      contactPreference:null,
      phone:'',
      email:'',
      confirmMessage:'Autoremind!',
      loading:false
    }
  },
  methods:{
    earliestDate(){
      return DateTime.now().plus({days:7}).toISODate()
    },
    latestDate(){
      return DateTime.now().plus({days:14}).toISODate()
    },
    checkNumber(){
      if(this.phone){
        this.phone = this.$refs.selector.phoneObject.number
      }
      this.showContact = false
      this.showAdditional = true
    },
    async autoremind(){
      this.showConfirmation = false
      this.loading=true
      let response = await axios.post(`${process.env.VUE_APP_AUTOREMINDR_API}/event/`,{
          'name':this.eventName,
          'startTime':this.dateTime.toISOString(),
          'contacts':[this.phone],
          'organizerName':"Dom",
          'organizerEmail':'dom@dompatmore.com'
      })
      if(response.status == 201){
        this.loading=false
        this.scheduled = true
      }

    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
